<template>
    <div>
      <design-product-list></design-product-list>
    </div>
  </template>
  
  <script>  
  export default {
  }
  </script>
  
  <style>
  
  </style>
  